.iq__footer{
    border-top: 1px solid rgba(176,190,197,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iq__footer a{
    text-decoration: none;
}

.iq__footer__social{
    display: none;
    margin-top: 12px;
}

.iq__footer__sections{
    display: flex;
    align-items: center;
    margin: 6px 0 12px 0;
}

.iq__footer__sections hr {
    height: 22px;
    margin: 0 8px;
}

.iq__footer__rights{
    max-width: 246px;
    text-align: center;
}

.iq__footer__warning{
    max-width: 222px;
    text-align: center;
}

.iq__footer__caption{
    font-size: 9px;
    color: #26323B;
    margin-bottom: 4px;
}

@media (min-width: 960px) {
    .iq__footer{
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 0
    }
    .iq__footer__sections{
        order: -1;
        margin: 0;
    }
    .iq__footer__social{
        margin: 0;
        display: none;
    }
}